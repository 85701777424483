import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  split,
} from "@apollo/client";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import {
  getMainDefinition,
  offsetLimitPagination,
} from "@apollo/client/utilities";
import { createClient } from "graphql-ws";
import WebSocket from "isomorphic-ws";

import { env } from "~/env.mjs";

import { getToken } from "./utils/token";

const httpLink = new HttpLink({
  uri: "/api/graphql",
});

const authLink = new ApolloLink((operation, forward) => {
  const token = getToken();
  if (token) {
    operation.setContext({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
  return forward(operation);
});

const wsLink = new GraphQLWsLink(
  createClient({
    webSocketImpl: WebSocket,
    url: env.NEXT_PUBLIC_WS_URL,
    connectionParams: () => ({
      authToken: getToken(),
    }),
  })
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  authLink.concat(httpLink)
);

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        packs: offsetLimitPagination(["sort", "type", "lang"]),
        collections: offsetLimitPagination(["sort"]),
        searchImages: offsetLimitPagination(["query"]),
      },
    },
    User: {
      fields: {
        packs: offsetLimitPagination(),
        stars: offsetLimitPagination(),
      },
    },
    Collection: {
      fields: {
        packs: offsetLimitPagination(),
      },
    },
    Category: {
      fields: {
        packs: offsetLimitPagination(["sort", "type", "lang"]),
      },
    },
  },
});

export const client = new ApolloClient({
  link: splitLink,
  cache,
});
