import { Button, FormControl, FormLabel } from "@chakra-ui/react";
import { useCart } from "@shopify/hydrogen-react";
import type { ProductVariant } from "@shopify/hydrogen-react/storefront-api-types";
import React, { useState } from "react";

import { QuantityInput } from "./QuantityInput";

type AddToCartProps = {
  variant: Pick<ProductVariant, "id" | "quantityAvailable">;
};

export const AddToCart = ({ variant }: AddToCartProps) => {
  const [quantity, setQuantity] = useState(1);
  const { linesAdd, status } = useCart();

  const { quantityAvailable } = variant;

  return (
    <>
      {quantityAvailable && (
        <FormControl
          display="flex"
          alignItems="flex-start"
          flexDir="column"
          mt={{ md: -2 }}
        >
          <FormLabel>Quantity</FormLabel>
          <QuantityInput
            quantity={quantity}
            max={quantityAvailable}
            onChange={setQuantity}
          />
        </FormControl>
      )}
      <div>
        <Button
          size="lg"
          isLoading={
            status === "fetching" ||
            status === "creating" ||
            status === "updating"
          }
          colorScheme="purple"
          isDisabled={!quantityAvailable}
          onClick={() => {
            linesAdd([
              {
                merchandiseId: variant.id,
                quantity,
              },
            ]);
          }}
        >
          {quantityAvailable ? "Add to cart" : "Out of stock"}
        </Button>
      </div>
    </>
  );
};
