import {
  Box,
  Button,
  Flex,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Stack,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { FiArrowRight } from "@react-icons/all-files/fi/FiArrowRight";
import { FiShoppingCart } from "@react-icons/all-files/fi/FiShoppingCart";
import { FiTrash2 } from "@react-icons/all-files/fi/FiTrash2";
import { CartCost, Image, useCart } from "@shopify/hydrogen-react";
import type { RefObject } from "react";
import React, { createContext, forwardRef, useImperativeHandle } from "react";

import { QuantityInput } from "./QuantityInput";

export const CartRefContext = createContext<RefObject<CartRef>>({
  current: null,
});

type CartRef = {
  open: () => void;
};

export const Cart = forwardRef<CartRef>((_, ref) => {
  const {
    lines,
    totalQuantity,
    checkoutUrl,
    linesUpdate,
    linesRemove,
    status,
  } = useCart();
  const { isOpen, onOpen, onClose } = useDisclosure();

  useImperativeHandle(
    ref,
    () => ({
      open: onOpen,
    }),
    [onOpen]
  );

  return (
    <Popover
      placement="bottom-end"
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
    >
      <PopoverTrigger>
        <Button
          rounded="full"
          size="sm"
          colorScheme="purple"
          variant="outline"
          leftIcon={<FiShoppingCart />}
        >
          Cart{totalQuantity !== 0 && ` (${totalQuantity})`}
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent w="sm">
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader>My cart</PopoverHeader>
          <PopoverBody>
            {lines?.length === 0 ? (
              <Box textAlign="center" py="8">
                {/* TODO: add empty state styles */}
                Your cart is empty
              </Box>
            ) : (
              <Stack spacing="2.5">
                {lines?.map((line) => {
                  if (!line) {
                    return null;
                  }

                  return (
                    <Flex align="flex-start" gap="2" key={line.id}>
                      {line.merchandise?.image && (
                        <Image
                          width={48}
                          height={48}
                          data={line.merchandise?.image}
                        />
                      )}
                      <Box w="full" lineHeight="short">
                        <div>{line.merchandise?.product?.title}</div>
                        <Box fontSize="sm">{line.merchandise?.title}</Box>
                      </Box>
                      {line.quantity && (
                        <QuantityInput
                          size="sm"
                          isDisabled={status === "updating"}
                          quantity={line.quantity}
                          onChange={(quantity) => {
                            if (line.id) {
                              linesUpdate([
                                {
                                  id: line.id,
                                  quantity,
                                },
                              ]);
                            }
                          }}
                        />
                      )}
                      <Tooltip label="Remove item">
                        <IconButton
                          variant="ghost"
                          size="sm"
                          aria-label="Remove item"
                          isDisabled={status === "updating"}
                          icon={<FiTrash2 />}
                          onClick={() => {
                            if (line.id) {
                              linesRemove([line.id]);
                            }
                          }}
                        />
                      </Tooltip>
                    </Flex>
                  );
                })}
                <Flex fontWeight="medium" justify="space-between">
                  <span>Subtotal</span>
                  <CartCost amountType="subtotal" />
                </Flex>
              </Stack>
            )}
          </PopoverBody>
          {lines?.length !== 0 && (
            <PopoverFooter>
              <Button
                w="full"
                as="a"
                isLoading={status === "updating"}
                href={checkoutUrl}
                colorScheme="purple"
                rightIcon={<FiArrowRight />}
              >
                Checkout
              </Button>
            </PopoverFooter>
          )}
        </PopoverContent>
      </Portal>
    </Popover>
  );
});

Cart.displayName = "Cart";
