import type { NumberInputProps } from "@chakra-ui/react";
import {
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

type QuantityInputProps = {
  quantity: number;
  onChange: (quantity: number) => void;
} & Omit<NumberInputProps, "onChange">;

export const QuantityInput = ({
  quantity,
  onChange,
  ...props
}: QuantityInputProps) => {
  const [value, setValue] = useState<string>(quantity.toString());

  useEffect(() => {
    setValue(quantity.toString());
  }, [quantity]);

  return (
    <NumberInput
      min={1}
      precision={0}
      value={value}
      onChange={(value) => {
        setValue(value);
      }}
      onBlur={(event) => {
        if (event.target.value === "") {
          // if the input is cleared, just set the value back to the quantity
          setValue(quantity.toString());
        } else {
          // otherwise send the implementing component the new clamped quantity
          onChange(Math.max(1, Number(event.target.value)));
        }
      }}
      {...props}
    >
      <NumberInputField />
      <NumberInputStepper>
        <NumberIncrementStepper />
        <NumberDecrementStepper />
      </NumberInputStepper>
    </NumberInput>
  );
};
