import { extendTheme, theme as baseTheme } from "@chakra-ui/react";
import { mix } from "color2k";

import { barlowCondensed, montserrat } from "./fonts";

const GRAY_700 = "#4e4e62";
const GRAY_800 = "#1d1e2f";

export const theme = extendTheme({
  semanticTokens: {
    colors: {
      error: {
        default: "red.500",
        _dark: "red.400",
      },
      bg: {
        default: "white",
        _dark: "gray.800",
      },
      modal: {
        default: "white",
        _dark: "gray.700",
      },
      bg2: {
        default: "gray.50",
        _dark: "gray.A700",
      },
      bgHover: {
        default: "gray.50",
        _dark: "whiteAlpha.200",
      },
      button: {
        default: "gray.100",
        _dark: "whiteAlpha.200",
      },
      accent: {
        default: "purple.500",
        _dark: "purple.300",
      },
      link: {
        default: "blue.500",
        _dark: "blue.300",
      },
      inactive: {
        default: "gray.300",
        _dark: "gray.600",
      },
    },
  },
  styles: {
    global: {
      strong: {
        fontWeight: "semibold",
      },
    },
  },
  layerStyles: {
    answer: {
      bg: "blackAlpha.50",
      _dark: {
        bg: "blackAlpha.500",
      },
      backdropFilter: "blur(8px)",
    },
  },
  textStyles: {
    display: {
      ...barlowCondensed.style,
      textTransform: "uppercase",
      lineHeight: "none",
      letterSpacing: "tight",
    },
    smallcaps: {
      lineHeight: "normal",
      fontWeight: "semibold",
      textTransform: "uppercase",
      letterSpacing: "wider",
    },
  },
  fonts: {
    body: montserrat.style.fontFamily,
    heading: montserrat.style.fontFamily,
  },
  fontSizes: {
    "7xl": "5rem",
  },
  components: {
    Button: {
      sizes: {
        xl: {
          h: 14,
          px: 7,
          fontSize: "xl",
          rounded: "lg",
        },
      },
    },
    Kbd: {
      baseStyle: {
        fontSize: "sm",
        lineHeight: "shorter",
        borderColor: "current",
        borderBottomWidth: "0.2em",
        bg: "none",
      },
    },
    Stat: {
      baseStyle: {
        label: {
          lineHeight: "normal",
        },
      },
    },
    Heading: {
      sizes: {
        sm: {
          fontSize: {
            base: "lg",
            md: "xl",
          },
        },
        md: {
          fontSize: {
            base: "xl",
            md: "2xl",
          },
        },
      },
    },
    Switch: {
      baseStyle: {
        container: {
          display: "flex",
        },
      },
    },
  },
  colors: {
    gray: {
      50: "#f8f8ff",
      100: "#f3f2ff",
      200: "#ebebff",
      300: "#dddcf4",
      400: "#b9b9d0",
      500: "#9a9ab0",
      600: "#717186",
      700: GRAY_700,
      800: GRAY_800,
      900: "#11121c",
      A700: mix(GRAY_700, GRAY_800, 0.5),
    },
    blue: baseTheme.colors.twitter,
    orange: {
      50: "#ffddd0",
      100: "#ffcebb",
      200: "#ffb092",
      300: "#ff936a",
      400: "#ff7541",
      500: "#ff5818",
      600: "#df3e00",
      700: "#a72e00",
      800: "#6f1f00",
      900: "#370f00",
    },
    purple: {
      50: "#ebe8fc",
      100: "#dbd6fa",
      200: "#bbb1f6",
      300: "#9b8df2",
      400: "#7b68ee",
      500: "#4f36e8",
      600: "#3117cf",
      700: "#25129c",
      800: "#190c6a",
      900: "#0d0637",
    },
    yellow: {
      50: "#feeecf",
      100: "#fee7bb",
      200: "#fdda93",
      300: "#fccc6b",
      400: "#fbbe43",
      500: "#fab01b",
      600: "#d89205",
      700: "#a16d04",
      800: "#6a4802",
      900: "#332301",
    },
    vk: {
      50: "#b8d9ff",
      100: "#a3ceff",
      200: "#7ab8ff",
      300: "#52a3ff",
      400: "#298dff",
      500: "#0077ff",
      600: "#005dc7",
      700: "#00438f",
      800: "#002857",
      900: "#000e1f",
    },
  },
});
