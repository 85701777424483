import { ApolloProvider } from "@apollo/client";
import { ChakraProvider } from "@chakra-ui/react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { CartProvider, ShopifyProvider } from "@shopify/hydrogen-react";
import type { AppProps } from "next/app";
import { useRouter } from "next/router";
import Script from "next/script";
import { DefaultSeo } from "next-seo";
import { GoogleAnalytics } from "nextjs-google-analytics";
import type { ElementRef } from "react";
import React, { useCallback, useEffect, useRef } from "react";

import type { Cart } from "~/components/Cart";
import { CartRefContext } from "~/components/Cart";
import { env } from "~/env.mjs";

import logo from "../assets/logo.svg";
import { client } from "../client";
import { theme } from "../theme";

export default function App({ Component, pageProps }: AppProps) {
  const router = useRouter();

  useEffect(() => {
    /**
     * This pageview only triggers the first time (it's important for Pixel to
     * have real information)
     */
    window.fbq("track", "PageView");

    const handleRouteChange = () => {
      window.fbq("track", "PageView");
    };

    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  const cartRef = useRef<ElementRef<typeof Cart>>(null);

  const openCart = useCallback(() => {
    cartRef.current?.open();
  }, []);

  return (
    <ShopifyProvider
      storeDomain={env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN}
      storefrontToken={env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_TOKEN}
      storefrontApiVersion={env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_API_VERSION}
      countryIsoCode="US"
      languageIsoCode="EN"
    >
      <CartProvider onCreateComplete={openCart} onLineAddComplete={openCart}>
        <CartRefContext.Provider value={cartRef}>
          <GoogleOAuthProvider clientId="326923585129-sanaa1008fidrbm5c9huijkk1smd8gc3.apps.googleusercontent.com">
            <ApolloProvider client={client}>
              <ChakraProvider theme={theme}>
                <GoogleAnalytics trackPageViews />
                <Script id="fb-pixel" strategy="afterInteractive">
                  {`
                    !function(f,b,e,v,n,t,s)
                    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                    n.queue=[];t=b.createElement(e);t.async=!0;
                    t.src=v;s=b.getElementsByTagName(e)[0];
                    s.parentNode.insertBefore(t,s)}(window, document,'script',
                    'https://connect.facebook.net/en_US/fbevents.js');
                    fbq('init', ${process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID});
                  `}
                </Script>
                <DefaultSeo
                  titleTemplate="%s - Knoword"
                  defaultTitle="Knoword"
                  additionalLinkTags={[
                    {
                      rel: "icon",
                      href: logo,
                    },
                  ]}
                  facebook={{
                    appId: "539568719723429",
                  }}
                  openGraph={{
                    site_name: "Knoword",
                    images: [{ url: "https://knoword.com/logo.png" }],
                  }}
                  twitter={{
                    cardType: "summary",
                    site: "@knoword",
                  }}
                />
                <Component {...pageProps} />
              </ChakraProvider>
            </ApolloProvider>
          </GoogleOAuthProvider>
        </CartRefContext.Provider>
      </CartProvider>
    </ShopifyProvider>
  );
}
